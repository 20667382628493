import React from "react";
import SingleArt from "../components/SingleArt";
import OneImageSlider from "../components/OneImageSlider";

function Artwork() {
  return (
    <section className="px-8 md:max-w-6xl py-20 mx-auto w-full" id="artwork">
      <div className="hidden md:block">
        <SingleArt />
      </div>
      <div className="block md:hidden">
        <OneImageSlider />
      </div>

      <div className="space-y-8 flex flex-col items-center mt-10">
        <h3 className="text-center text-base md:text-2xl">
          The Mod HippoGryph Creed
        </h3>
        <p className="text-justify md:text-center text-sm md:text-lg">
          In the VentureVerse, Mod creators maintain control over their ideas,
          their ventures, and Mod investors maintain control over their money,
          their investments. Both are aligned by freedom, passion and abundance.
          In the VentureVerse, Mods have equal access to how value is created by
          learning the magical language of the VentureVerse, the language of
          value creation, a secret language only available to members of this
          new realm.
        </p>
      </div>
    </section>
  );
}

export default Artwork;
