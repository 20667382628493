import mask from "../img/mask.png";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

function Roadmap() {
  return (
    <section className="px-8 md:max-w-6xl py-12 mx-auto w-full" id="roadmap">
      <h2 className="text-3xl md:text-5xl text-center">Roadmap</h2>
      <div className="flex flex-col items-center justify-between w-full">
        <Tabs>
          <TabList className="flex md:space-x-20 py-8">
            <Tab className="cursor-pointer">
              <div className="flex flex-col items-center">
                <img src={mask} alt="" />
                <h2 className="text-2xl md:text-5xl -mt-28">Pre-Mint</h2>
              </div>
            </Tab>
            <Tab className="cursor-pointer ">
              <div className="flex flex-col items-center">
                <img src={mask} alt="" className="" />
                <h2 className="text-2xl md:text-5xl -mt-28">Post-Mint</h2>
              </div>
            </Tab>
          </TabList>

          <TabPanel>
            <h2 className="text-2xl md:text-4xl mt-20 italic font-semibold">
              <span className="text-blue">Episode 1:</span> Pre-Mint Perks
            </h2>
            <div className="py-20 flex flex-col space-y-2">
              <div className="w-1 h-24 bg-white shadow-md inset-0 line"></div>
              {/* single */}
              <div className="flex space-x-5">
                <div>
                  <div className="bg-white rounded-full h-5 w-5 -ml-2"></div>
                  <div className="w-1 h-full bg-white shadow-md inset-0 mt-2"></div>
                </div>
                <div className="p-3 md:p-6 -mt-8">
                  <h2 className="text-2xl md:text-4xl text-primary font-bold font-Kalam pt-1">
                    1st 50% Sellout Perks :
                  </h2>
                  <ul className="text-white p-5 mt-5 leading-6 space-y-12">
                    {/* ==== Single element==== */}
                    <div className="space-y-3">
                      <div>
                        <h2 className="text-2xl font-bold text-blue">
                          Gold Club
                        </h2>
                        <p>20 SST Holder</p>
                      </div>
                      <div className="text-sm">
                        <p>1 Randomly drawn wallet shall win.</p>
                        <p>1 Mutant Ape.</p>
                      </div>
                    </div>
                    {/* ==== Single element==== */}
                    <div className="space-y-3">
                      <div>
                        <h2 className="text-2xl font-bold text-blue">
                          Silver Club
                        </h2>
                        <p>10 SST Holder</p>
                      </div>
                      <div className="text-sm">
                        <p>5 Randomly drawn wallet shall win.</p>
                        <p>5 Bored Ape Kennels each.</p>
                      </div>
                    </div>
                    {/* ==== Single element==== */}
                    <div className="space-y-3">
                      <div>
                        <h2 className="text-2xl font-bold text-blue">
                          Prime Club
                        </h2>
                        <p>5 SST Holder</p>
                      </div>
                      <div className="text-sm">
                        <p>10 Randomly drawn wallets shall win.</p>
                        <p>10 Hape Prime NFTs each.</p>
                      </div>
                    </div>

                    {/* ==== Single element==== */}
                    <div className="space-y-3">
                      <div>
                        <h2 className="text-2xl font-bold text-blue">
                          Holders Club
                        </h2>
                        <p>1 SST Holder</p>
                      </div>
                      <div className="text-sm">
                        <p>30 Randomly drawn wallets shall win.</p>
                        <p>30 Hape Prime NFTs each.</p>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
              {/* single */}
              <div className="flex space-x-5">
                <div>
                  <div className="bg-white rounded-full mt-8 h-5 w-5 -ml-2"></div>
                  <div className="w-1 h-full bg-white shadow-md inset-0 mt-2 line2"></div>
                </div>
                <div className="p-3 md:p-6">
                  <h2 className="text-2xl md:text-4xl text-primary font-bold font-Kalam pt-1">
                    2nd 50% Sellout Perks :
                  </h2>
                  <ul className="text-white p-5  leading-6 space-y-12">
                    <p className="text-base italic">
                      Perks to be announced after rewarding the lucky draw
                      members.
                    </p>
                  </ul>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <h2 className="text-2xl md:text-4xl mt-20 italic font-semibold">
              Episode 2: THE COMMUNITY UTLITY
            </h2>
            <div className="py-20 flex flex-col space-y-2">
              <div className="w-1 h-24 bg-white shadow-md inset-0 line"></div>
              {/* single */}
              <div className="flex space-x-5">
                <div>
                  <div className="bg-white rounded-full h-5 w-5 -ml-2"></div>
                  <div className="w-1 h-full bg-white shadow-md inset-0 mt-2"></div>
                </div>
                <div className="p-3 md:p-6 -mt-8">
                  <h2 className="text-2xl md:text-4xl text-primary font-bold  pt-1">
                    20%
                  </h2>
                  <ul className="text-white px-5 pb-16 leading-6 ">
                    {/* ==== Single element==== */}
                    <div className="text-xl mt-3 space-y-2 text-blue">
                      <p>Staking NFT to Earn Passive Income.</p>
                      <p>Whitelist to Grandpa Saber.</p>
                    </div>
                  </ul>
                </div>
              </div>

              {/* single */}
              <div className="flex space-x-5">
                <div>
                  <div className="bg-white rounded-full h-5 w-5 -ml-2"></div>
                  <div className="w-1 h-full bg-white shadow-md inset-0 mt-2"></div>
                </div>
                <div className="p-3 md:p-6 -mt-8">
                  <h2 className="text-2xl md:text-4xl text-primary font-bold  pt-1">
                    40%
                  </h2>
                  <ul className="text-white px-5 pb-16 leading-6 ">
                    {/* ==== Single element==== */}
                    <div className="text-xl mt-3 space-y-2 text-blue">
                      <p>Hoardings</p>
                      <p>Brand Collaboration</p>
                    </div>
                  </ul>
                </div>
              </div>

              {/* single */}
              <div className="flex space-x-5">
                <div>
                  <div className="bg-white rounded-full h-5 w-5 -ml-2"></div>
                  <div className="w-1 h-full bg-white shadow-md inset-0 mt-2"></div>
                </div>
                <div className="p-3 md:p-6 -mt-8">
                  <h2 className="text-2xl md:text-4xl text-primary font-bold  pt-1">
                    60%
                  </h2>
                  <ul className="text-white px-5 pb-16 leading-6 ">
                    {/* ==== Single element==== */}
                    <div className="text-xl mt-3 space-y-2 text-blue">
                      <p>Developing real estate activity unit at Metaverse</p>
                      <p>Investment incubator for Ideas and Investors</p>
                    </div>
                  </ul>
                </div>
              </div>

              {/* single */}
              <div className="flex space-x-5">
                <div>
                  <div className="bg-white rounded-full mt-8 h-5 w-5 -ml-2"></div>
                  <div className="w-1 h-full bg-white shadow-md inset-0 mt-2 line2"></div>
                </div>
                <div className="p-3 md:p-6">
                  <h2 className="text-2xl md:text-4xl text-primary font-bold font-Kalam pt-1">
                    100%
                  </h2>
                  <ul className="text-white px-5 pb-16 leading-6 ">
                    {/* ==== Single element==== */}
                    <div className="text-xl mt-3 space-y-2 text-blue">
                      <p>Crypto Utility Firm To Be Announced </p>
                      <p>First Holders To Be On the Board of this firm</p>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </section>
  );
}

export default Roadmap;
