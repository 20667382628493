import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

function Forms() {
  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const initialValues = {
    name: "",
    email: "",
    age: "",
    location: "",
  };

  const validate = (values) => {
    let errors = {};

    if (!values.name) {
      errors.name = "Please type your name";
    }
    if (!values.age) {
      errors.age = "Please type your age";
    }

    if (!values.location) {
      errors.location = "Please type your Location";
    }

    if (!values.email) {
      errors.email = "Please type your email";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Please type a valid email";
    }

    return errors;
  };

  const onSubmit = (values, submitProps) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...values }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status);
        } else if (response.ok) {
          alert("Success!");
          submitProps.resetForm();
        } else {
          alert("Something went wrong!");
        }

        return response;
      })
      .catch((error) => alert(error));
  };

  return (
    <section className="mx-auto py-10 w-full px-8 md:max-w-6xl">
      <div className="mx-auto mt-10 rounded-md bg-lightblack p-5 shadow-md md:max-w-lg">
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
        >
          <Form className=" text-lg space-y-4">
            <input type="hidden" name="form-name" value="contact" />
            <div className="flex flex-col space-y-2">
              <label>Name:</label>
              <Field
                type="name"
                id="name"
                name="name"
                placeholder="Name"
                className="rounded-md border-2 text-black border-white bg-lightblack py-2 px-2"
              />
              <div className="text-red text-sm ml-1 px-1 max-w-max">
                <ErrorMessage name="name" />
              </div>
            </div>

            <div className="flex flex-col space-y-2">
              <label>Email Address:</label>
              <Field
                type="email"
                id="email"
                name="email"
                placeholder="Email Address"
                className="rounded-md border-2 text-black border-white bg-lightblack py-2 px-2"
              />
              <div className="text-red text-sm ml-1 px-1 max-w-max">
                <ErrorMessage name="email" />
              </div>
            </div>

            <div className="flex flex-col space-y-2">
              <label>Age:</label>
              <Field
                type="age"
                id="age"
                name="age"
                placeholder="Age"
                className="rounded-md border-2 text-black border-white bg-lightblack py-2 px-2"
              />
              <div className="text-red text-sm ml-1 px-1 max-w-max">
                <ErrorMessage name="age" />
              </div>
            </div>

            <div className="flex flex-col space-y-2">
              <label>Location:</label>
              <Field
                type="location"
                id="location"
                name="location"
                placeholder="Location"
                className="rounded-md border-2 text-black border-white bg-lightblack py-2 px-2"
              />
              <div className="text-red text-sm ml-1 px-1 max-w-max">
                <ErrorMessage name="location" />
              </div>
            </div>
            {/* 
            <div className="flex flex-col text-green-100 mt-4">
              <label>Message:</label>
              <Field
                as="textarea"
                id="message"
                name="message"
                placeholder="Message"
                className="pl-2 py-2 bg-green-50 text-gray-800 rounded focus:outline-none focus:ring-4 focus:ring-gray-700"
              />
              <ErrorMessage name="message" />
            </div> */}

            <button className=" text-white rounded-md bg-blue border border-white px-3 py-2 font-semibold shadow-md">
              Submit
            </button>
          </Form>
        </Formik>
      </div>
    </section>
  );
}

export default Forms;
