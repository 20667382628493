import team1 from "../img/team-1.gif";
import team2 from "../img/team2.png";
import team3 from "../img/team3.png";
import team4 from "../img/team4.png";
import team5 from "../img/team5.png";
import team6 from "../img/team6.png";

export default [
  {
    id: 1,
    image: team1,
    name: "ogMod",
    role: "Founder",
  },
  {
    id: 2,
    image: team1,
    name: "ngModess",
    role: "Partner",
  },
  {
    id: 3,
    image: team1,
    name: "ygMod",
    role: "Developer",
  },
  {
    id: 4,
    image: team1,
    name: "bgMod",
    role: "Shaman",
  },

  {
    id: 5,
    image: team1,
    name: "Name-X",
    role: "Role-X",
  },
  {
    id: 6,
    image: team1,
    name: "Name-Y",
    role: "Role-Y",
  },
];
