import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Home from "../pages/Home";

//

import Mint from "../Mint";
import Manifesto from "../pages/Manifesto";
import Test from "../pages/Test";
import Claim from "../Claim";
import Bite from "../pages/Bite";
import Voting from "../pages/Voting";
// import ContactForm from "../pages/ContactForm";
import Forms from "../pages/Forms";

function routes() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/mint">
          <Mint />
        </Route>
        <Route path="/manifesto">
          <Manifesto />
        </Route>
        <Route path="/roadmap">
          <Test />
        </Route>
        <Route path="/claim">
          <Claim />
        </Route>
        <Route path="/bite">
          <Bite />
        </Route>
        <Route path="/voting">
          <Voting />
        </Route>
        <Route path="/forms">
          <Forms />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default routes;
