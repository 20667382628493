import React, { useEffect, useState, useRef } from "react";
// import sun from "../assets/img/Sun.png";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";

function Voting() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  var [totalSupply, setTotalSupply] = useState(0);
  var [pollOptions, setPollOptions] = useState(false);
  var [question, setQuestion] = useState("Please wait...loading the question.");
  const [feedback, setFeedback] = useState(``);
  const [votingStatus, setVotingStatus] = useState(false);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const voteThis = (e) => {
    e.preventDefault();
    var chosenOption = e.target.value;

    blockchain.smartContract.methods
      .castVote(chosenOption)
      .send({
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(`Voting Completed`);
        dispatch(fetchData(blockchain.account));
      });
  };

  const fetchQuestionData = async () => {
    var pollState = await blockchain.smartContract.methods.pollState().call();
    setVotingStatus(pollState);

    var question = await blockchain.smartContract.methods.pollName().call();
    var options = await blockchain.smartContract.methods
      .getPollOptions()
      .call();
    var totalVotes = await blockchain.smartContract.methods
      .totalSupply()
      .call();
    setTotalSupply(totalVotes);

    if (question == "") {
      setQuestion("No Poll so far.");
      return;
    }
    setQuestion(question);

    console.log("total values", totalVotes);
    var votes = []; // vote option, vote Count
    var voteCount = 0;

    for (var i in options) {
      var eachOption = options[i];
      voteCount = await blockchain.smartContract.methods
        .getVoteCountForOption(eachOption)
        .call();
      votes.push([eachOption.toString(), voteCount.toString()]);
    }

    setPollOptions(votes);
  };

  const getData = () => {
    setQuestion("Loading poll...");
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      fetchQuestionData();
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
    dispatch(connect());
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <div className="">
      <div className="px-5 md:px-20 py-7 mx-auto w-full">
        <div className="flex py-10  rounded-md shadow-md">
          <div className="flex justify-center items-center w-full  px-6 py-6 md:px-10 md:py-10 md:space-x-10">
            <div className="">
              {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                <></>
              ) : (
                <>
                  {blockchain.account === "" ||
                  blockchain.smartContract === null ? (
                    <div className="flex flex-col items-center justify-center w-full">
                      <h3 className="text-white text-center font-Kalam font-bold text-base md:text-2xl md:tracking-wider">
                        Connect to the {CONFIG.NETWORK.NAME} network
                      </h3>

                      <button
                        className="bg-primary text-white px-8 py-3 rounded-md font-Kalam tracking-wider mt-16"
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(connect());
                          getData();
                        }}
                      >
                        CONNECT
                      </button>
                      {blockchain.errorMsg !== "" ? (
                        <>
                          <h2 class="text-center text-white mt-5 font-semibold font-Opensans">
                            {blockchain.errorMsg}
                          </h2>
                        </>
                      ) : null}
                    </div>
                  ) : (
                    // the vote section
                    <>
                      {votingStatus === false ? (
                        <div>
                          <h2> Hold till a poll starts.</h2>
                        </div>
                      ) : (
                        <section className="px-8 md:px-32 mx-auto w-full py-5">
                          <h2 className="text-3xl md:text-5xl  text-center ">
                            Voting
                          </h2>
                          <h4 className="title text-yellow font-semibold text-center ">
                            {" "}
                            {feedback}{" "}
                          </h4>
                          <p className="text-lg text-center mt-5">
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Amet, facilis vel? Quidem ipsam, quis sequi
                            quaerat libero unde ducimus quae? Lorem ipsum dolor
                            sit amet, consectetur adipisicing elit. Amet,
                            facilis vel? Quidem ipsam, quis sequi quaerat libero
                            unde ducimus quae?
                          </p>
                          <div>
                            <div className="flex flex-col  rounded-md shadow-md items-center mt-5">
                              <div className="space-y-10 md:space-y-20">
                                <div className="p-8 flex flex-col space-y-7">
                                  <div className="space-y-3">
                                    <h2 className="text-base text-center font-semibold md:text-2xl text-red">
                                      CURRENT VOTE
                                    </h2>
                                    <p className="text-sm md:text-lg bg-blue py-2 px-3 rounded-md">
                                      <span className="text-red">
                                        Question:
                                      </span>{" "}
                                      {question}
                                    </p>
                                  </div>
                                  <div className="space-y-4 ">
                                    {pollOptions === false ? null : (
                                      <>
                                        {pollOptions.map((optionData) => (
                                          <div className="space-y-2">
                                            <div className="flex items-center justify-between text-base">
                                              <p>{optionData[0]} </p>
                                              <p>
                                                {optionData[1]} / {totalSupply}{" "}
                                              </p>
                                              <p>
                                                <button
                                                  value={optionData[0]}
                                                  onClick={voteThis}
                                                  className="bg-blue px-4 py-2 rounded-md text-white text-base"
                                                >
                                                  Vote
                                                </button>
                                              </p>
                                            </div>

                                            <div className="blue-1 h-5 w-full shadow-md rounded-full">
                                              <div
                                                className={`blue h-5 w-${Math.round(
                                                  (parseInt(optionData[1]) /
                                                    parseInt(totalSupply)) *
                                                    5
                                                )}/5 shadow-md rounded-full w-${
                                                  optionData[1] === 0
                                                } ? w-0`}
                                              ></div>
                                            </div>
                                          </div>
                                        ))}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Voting;
