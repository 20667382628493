import React, { useState } from "react";
import logo from "../img/logo2.png";
import { Link } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";

import twitter from "../img/twitter.svg";
import tiktok from "../img/discord.svg";
import linkedin from "../img/linkedin.svg";
import instagram from "../img/instagram.svg";

function Header() {
  const [showLinks, setShowLinks] = useState(true);

  return (
    <header className="fixed w-full bg-black z-50">
      <div className="px-8 md:max-w-6xl py-2 mx-auto w-full">
        <div className="nav-center">
          <div className="md:flex items-center">
            <div className="nav-header space-x-10">
              <figure>
                <Link to="/">
                  <img src={logo} alt="Logo" className="h-10 md:h-16" />
                </Link>
              </figure>
              <button onClick={() => setShowLinks(!showLinks)}>
                <AiOutlineMenu className="md:hidden text-white absolute top-8 md:top-14 right-10 text-2xl cursor-pointer" />
              </button>
            </div>
            <div
              className={`${
                showLinks ? "links-container show-container" : "show-container"
              }`}
            >
              <ul className="links text-white text-base md:text-base md:space-x-2 font-semibold">
                {/* <li className="links">
                  <Link
                    onClick={() => setShowLinks(true)}
                    className="link"
                    to="/"
                  >
                    Home
                  </Link>
                </li> */}
                <li className="links">
                  <a
                    onClick={() => setShowLinks(true)}
                    className="link"
                    href="#rise"
                  >
                    About
                  </a>
                </li>
                {/* <li className="links">
                  <a
                    onClick={() => setShowLinks(true)}
                    className="link"
                    href="#artwork"
                  >
                    Artwork
                  </a>
                </li> */}
                <li className="links">
                  <a
                    onClick={() => setShowLinks(true)}
                    className="link"
                    href="/roadmap"
                  >
                    Roadmap
                  </a>
                </li>
                {/* <li className="links">
                  <a
                    onClick={() => setShowLinks(true)}
                    className="link"
                    href="/manifesto"
                  >
                    Manifesto
                  </a>
                </li> */}
                <li className="links">
                  <a
                    onClick={() => setShowLinks(true)}
                    className="link"
                    href="#team"
                  >
                    Team
                  </a>
                </li>
                {/* <li className="links">
                  <a
                    onClick={() => setShowLinks(true)}
                    className="link"
                    href="/claim"
                  >
                    Claim
                  </a>
                </li> */}
                {/* <li className="links">
                  <a
                    onClick={() => setShowLinks(true)}
                    className="link"
                    href="/bite"
                  >
                    $LGN
                  </a>
                </li> */}
                <li className="links">
                  <a
                    onClick={() => setShowLinks(true)}
                    className="link"
                    href="#faq"
                  >
                    FAQs
                  </a>
                </li>
                {/* <li className="links">
                  <a
                    onClick={() => setShowLinks(true)}
                    className="link"
                    href="/voting"
                  >
                    Voting
                  </a>
                </li> */}
                {/* <li className="links">
                  <Link
                    onClick={() => setShowLinks(true)}
                    className="link"
                    to="/forms"
                  >
                    Whitelist
                  </Link>
                </li> */}

                <li className="block md:hidden">
                  <ul className="flex space-x-3">
                    <a href="#" target="_blank">
                      <img className="" src={twitter} alt="Instagram" />
                    </a>

                    <a href="#" target="_blank">
                      <img className="" src={instagram} alt="Instagram" />
                    </a>
                    {/* <a href="#" target="_blank">
                      <img className="" src={linkedin} alt="Instagram" />
                    </a> */}
                    <a href="#" target="_blank">
                      <img className="" src={tiktok} alt="Instagram" />
                    </a>
                  </ul>
                </li>
                {/* <li className="links md:hidden mt-3 md:mt-0">
                  <a
                    href=""
                    target="_blank"
                    className="link text-white px-8 py-2 rounded-md md:text-sm font-bold inline-block bg-green"
                  >
                    Mint
                  </a>
                </li> */}
              </ul>
            </div>
          </div>

          <div className="social-icons flex items-center space-x-8">
            <div className="space-x-2 flex items-center">
              <a href="#" target="_blank">
                <img className="h-5" src={twitter} alt="Instagram" />
              </a>

              <a href="#" target="_blank">
                <img className="h-5" src={instagram} alt="Instagram" />
              </a>
              {/* <a href="#" target="_blank">
                <img className="" src={linkedin} alt="Instagram" />
              </a> */}
              <a href="#" target="_blank">
                <img className="h-6" src={tiktok} alt="Instagram" />
              </a>
            </div>
            <Link
              to="/forms"
              className=" text-white social-icons  border border-white rounded-md bg-blue px-8 py-2 text-base "
            >
              Whitelist
            </Link>
          </div>
        </div>
        <Link
          to="/forms"
          className="inline-block md:hidden text-white rounded-full bg-blue border border-white px-8 py-4 text-base box top-12"
        >
          Whitelist
        </Link>
      </div>
    </header>
  );
}

export default Header;
