import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import mask from "../img/mask.png";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Test() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <section className="px-8 md:max-w-6xl py-28 mx-auto w-full flex flex-col items-center">
      <div className="flex flex-col items-center">
        <img src={mask} alt="" />
        <h2 className="text-3xl md:text-5xl -mt-28">Roadmap</h2>
      </div>
      <div className="flex flex-col items-center justify-between w-full">
        <div className="mt-10">
          <Box sx={{ width: "100%" }}>
            <Box className="flex items-center justify-center">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab className="tab tab1" label="Pre-Mint" {...a11yProps(0)} />
                <Tab className="tab tab2" label="Post-Mint" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <div>
                <h2 className="text-2xl md:text-4xl mt-20 italic font-semibold">
                  <span className="text-purple">Episode 1:</span>
                  MoonShot Launch
                </h2>
                <div className="py-20 flex flex-col space-y-2">
                  <div className="w-1 h-24 bg-white shadow-md inset-0 line"></div>
                  {/* single */}
                  <div className="flex space-x-5">
                    <div>
                      <div className="bg-white rounded-full h-5 w-5 -ml-2"></div>
                      <div className="w-1 h-full bg-white shadow-md inset-0 mt-2"></div>
                    </div>
                    <div className="p-3 md:p-6 -mt-8">
                      <h2 className="text-xl md:text-3xl text-primary font-bold text-purple pt-1">
                        Phase 1: 50% Sellout Perks
                      </h2>
                      <p className="text-sm p-3">
                        {/* Phase 1 of the Sneaky Sabor Tooth SellOut starts from
                        the first pre-sale and ends at the 50% sales milestone.
                        Here’s what the legendary tiger has planned for you: */}
                      </p>
                      <ul className="text-white p-5 leading-6 space-y-12 ">
                        <li>
                          <span className="text-lg font-semibold">
                            Explorer Club <br />
                          </span>
                          <ul className="text-white p-5 leading-6 space-y-3 list-disc">
                            <li>20 MHG Holders</li>
                            <li>
                              5 Random winners access to the initial Moonshot
                              funding rounds of $1M or more each.
                            </li>
                            <li>Access to Magic Island Retreats</li>
                            <li>
                              First to take place Q1 2023 in the Canary Islands
                            </li>
                            <li>
                              3 days to attract capital, 3 months to close
                              capital, 3 years to your MoonShot
                            </li>
                            <li>
                              Manifestation and Funding Coaching - with the
                              ogMod and ngModess
                            </li>
                          </ul>
                        </li>
                        <li>
                          <span className="text-lg font-semibold">
                            Pioneer Club <br />
                          </span>
                          <ul className="text-white p-5 leading-6 space-y-3 list-disc">
                            <li>10 MHG Holders</li>
                            <li>
                              10 Random winners entrance to the Explorer Club
                            </li>
                            <li>Priority Access to Moonshot Funding</li>
                            <li>First Look (whitelist) MoonShot Investing</li>
                          </ul>
                        </li>
                        <li>
                          <span className="text-lg font-semibold">
                            Maverick Club
                            <br />
                          </span>
                          <ul className="text-white p-5 leading-6 space-y-3 list-disc">
                            <li>5 MHG Holders</li>
                            <li>
                              5 Random winners entrance to the Explorer Club
                            </li>
                            <li>First Look (whitelist) MoonShot Investing</li>
                          </ul>
                        </li>
                        <li>
                          <span className="text-lg font-semibold">
                            Adventurer Club <br />
                          </span>
                          <ul className="text-white p-5 leading-6 space-y-3 list-disc">
                            <li>1 MHG Holders</li>
                            <li>
                              1 Random winner entrance to the Explorer Club
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* single */}
                  <div className="flex space-x-5">
                    <div>
                      <div className="bg-white rounded-full mt-8 h-5 w-5 -ml-2"></div>
                      <div className="w-1 h-full bg-white shadow-md inset-0 mt-2 line2"></div>
                    </div>
                    <div className="p-3 md:p-6">
                      <h2 className="text-xl md:text-3xl text-primary font-bold text-purple pt-1">
                        Phase 2: Next 50% Sellout
                      </h2>
                      <ul className="text-white py-4 leading-6 space-y-4">
                        <p className="text-base">
                          Upon welcoming/rewarding the members of the first
                          mystery school on the magical island, the HippoGryph
                          will unravel the mysteries of this second phase
                          sellout and its rewards.
                        </p>
                        <p className="text-base">
                          Phase 2 will be even more ambitious, daring and
                          exhilarating than Phase 1. However this phase is
                          reserved for only those deserving of its magic.
                        </p>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div>
                <h2 className="text-2xl md:text-4xl mt-20 italic font-semibold">
                  <span className="text-purple">Episode 2:</span> The Magic
                  Island of Sancti
                </h2>

                <div className="py-20 flex flex-col space-y-2">
                  <div className="w-1 h-24 bg-white shadow-md inset-0 line"></div>
                  {/* single */}
                  <div className="flex space-x-5">
                    <div>
                      <div className="bg-purple rounded-full h-5 w-5 -ml-2"></div>
                      <div className="w-1 h-full bg-purple shadow-md inset-0 mt-2"></div>
                    </div>
                    <div className="p-3 md:p-6 -mt-8">
                      <h2 className="text-xl md:text-3xl text-primary font-bold  text-purple pt-1">
                        01. Mod Mystery School of Sorcery
                      </h2>
                      <p className="text-sm p-3">
                        The Mod Hippogryph knows that to get to the moon and
                        back the rider must master the mystical powers of
                        sorcery, alchemy and the power of manifestation. In the
                        VentureVerse the freedom to launch your MoonShot begins
                        here.
                      </p>
                      <ul className="text-white p-5 leading-6 space-y-12 list-disc">
                        <li>
                          <div className="flex items-center space-x-2">
                            <span className="text-lg font-semibold">
                              Council of Atlantes
                            </span>
                            <div className="rounded-md py-1 font-semibold px-4 text-purple border-2 border-purplee">
                              Done!
                            </div>
                            <br />
                          </div>
                          With dedicated and exclusive quest forums and meeting
                          spaces, including Discord and other venues, the adepts
                          will always have a say in which quests get funded in
                          the VentureVerse.
                        </li>
                        <li>
                          <div className="flex items-center space-x-2">
                            <span className="text-lg font-semibold">
                              Digital Alchemy
                            </span>
                            <div className="rounded-md py-1 font-semibold px-4 text-purple border-2 border-purple">
                              Done!
                            </div>
                            <br />
                          </div>
                          Using our secret mystical language of valuation and
                          creation, we show our adepts how to master the
                          alchemical process of turning their physical quest,
                          their mission and their creation into the 2D and then
                          the 3D of the Metaverse.
                        </li>
                        <li>
                          <div className="flex items-center space-x-2">
                            <span className="text-lg font-semibold">
                              MoonShot Dreaming
                            </span>
                            <div className="rounded-md py-1 font-semibold px-4 text-purple border-2 border-purple">
                              Done!
                            </div>
                            <br />
                          </div>
                          With both physical and digital events we help propel
                          our initiates to succeed at their quests faster and
                          with less friction. The Mod Hippogryph employs the
                          retreat, manifest and catapult philosophy to get you
                          to your Moon and back
                        </li>
                      </ul>
                    </div>
                  </div>

                  {/* single */}
                  <div className="flex space-x-5">
                    <div>
                      <div className="bg-white rounded-full h-5 w-5 -ml-2"></div>
                      <div className="w-1 h-full bg-white shadow-md inset-0 mt-2"></div>
                    </div>
                    <div className="p-3 md:p-6 -mt-8">
                      <h2 className="text-xl md:text-3xl text-primary font-bold text-purple  pt-1">
                        02. Grand Bazaar of Atlantes
                      </h2>
                      <p className="text-sm p-3">
                        In the Grand Bazaar those adepts and initiates who have
                        mastered digital alchemy can market their ideas, their
                        quests, their creations, in the 3D for the fuel the
                        HippoGryph requires for the journey to the Moon and back
                      </p>
                      <ul className="text-white p-5 leading-6 space-y-12 list-disc">
                        <li>
                          <span className="text-lg font-semibold">
                            Digital Stalls <br />
                          </span>
                          Every quest, every venture that the Council of
                          Atlantes approves for funding will have a digital
                          stall, a VentureVerse quest profile, at the Grand
                          Bazaar. Every stall is the same for everyone yet
                          unique to that venture. This makes for a more scalable
                          and efficient Bazaar
                        </li>
                        <li>
                          <span className="text-lg font-semibold">
                            Fair Trade <br />
                          </span>
                          In the Grand Bazaar price and valuation are stable and
                          not determined by hype and “venture of the day”. The
                          secret formula that determines price and valuation is
                          independent of external forces and impervious to the
                          most powerful magic and sorcery known. Where every
                          seller and buyer are on an even playing field
                        </li>
                        <li>
                          <span className="text-lg font-semibold">
                            Stable Currency
                            <br />
                          </span>
                          The currency that supports this market is not from the
                          Sorcerers stone. The market will only accept stable
                          currencies supported by physical economies 5 and not
                          subject to wild market fluctuation or magical potions
                          from the dark side. The value of this market is the
                          quest and the quest only.
                        </li>
                      </ul>
                    </div>
                  </div>

                  {/* single */}
                  <div className="flex space-x-5">
                    <div>
                      <div className="bg-white rounded-full h-5 w-5 -ml-2"></div>
                      <div className="w-1 h-full bg-white shadow-md inset-0 mt-2"></div>
                    </div>
                    <div className="p-3 md:p-6 -mt-8">
                      <h2 className="text-xl md:text-3xl text-primary font-bold text-purple  pt-1">
                        03. The Mod Route
                      </h2>
                      <p className="text-sm p-3">
                        Like the Spice Routes that linked the Western world with
                        the Middle East and Asia, the Mod Road is a route that
                        links the magical cities of the VentureVerse and the
                        adepts who have mastered the Mod Mystery School and
                        taken this secret language to other adepts and initiates
                        in the Mod cities of the world. Where quests are fertile
                        and ideation and mission are supported and funded every
                        day
                      </p>
                      <ul className="text-white p-5 leading-6 space-y-12 list-disc">
                        <li>
                          <span className="text-lg font-semibold">
                            The Mod Conquest
                            <br />
                          </span>
                          Mod Cities, connected by the Mod Road of master
                          creators and investors prolific in the creating,
                          building and growing of their quests and ventures
                          create a global quest network and economy. Members of
                          the VentureVerse can find freedom, passion and
                          abundance in our Quest economy.
                        </li>
                        <li>
                          <span className="text-lg font-semibold">
                            The Quest Economy <br />
                          </span>
                          At the center of this new realm is the quest, the
                          venture, and the creators, builders and investors who
                          fund, build, grow, buy and sell quests. The Quest
                          Economy is supported by 2D and 3D platforms built on
                          the secret language of the VentureVerse.
                        </li>
                        <li>
                          <span className="text-lg font-semibold">
                            The Mod Network
                            <br />
                          </span>
                          And you, the members of the VentureVerse, and holder
                          of the Mod Hippogryph, will be connected in the 2D and
                          3D worlds. The mission of the VentureVerse quest is to
                          let Mods live where they want, work on what they want
                          and make money any way they want to as long as its in
                          support of the Mod HippoGryph and his quest to bring
                          as many riders to the Moon and back as possible. And
                          being an impossible creature the number is limitless
                        </li>
                      </ul>
                    </div>
                  </div>

                  {/* single */}
                  {/* <div className="flex space-x-5">
                    <div>
                      <div className="bg-white rounded-full h-5 w-5 -ml-2"></div>
                      <div className="w-1 h-full bg-white shadow-md inset-0 mt-2"></div>
                    </div>
                    <div className="p-3 md:p-6 -mt-8">
                      <h2 className="text-xl md:text-3xl text-primary font-bold text-purple  pt-1">
                        04. Digital Alchemy
                      </h2>
                  
                    </div>
                  </div> */}

                  {/* single */}
                  {/* <div className="flex space-x-5">
                    <div>
                      <div className="bg-white rounded-full h-5 w-5 -ml-2"></div>
                      <div className="w-1 h-full bg-white shadow-md inset-0 mt-2"></div>
                    </div>
                    <div className="p-3 md:p-6 -mt-8">
                      <h2 className="text-xl md:text-3xl text-primary font-bold text-purple  pt-1">
                        05. Quest Profile
                      </h2>
                    
                    </div>
                  </div> */}

                  {/* single */}
                  {/* <div className="flex space-x-5">
                    <div>
                      <div className="bg-white rounded-full h-5 w-5 -ml-2"></div>
                      <div className="w-1 h-full bg-white shadow-md inset-0 mt-2"></div>
                    </div>
                    <div className="p-3 md:p-6 -mt-8">
                      <h2 className="text-xl md:text-3xl text-primary font-bold text-purple  pt-1">
                        06. Events and Retreat Platform
                      </h2>
                     
                    </div>
                  </div> */}

                  {/* <div className="flex space-x-5">
                    <div>
                      <div className="bg-white rounded-full h-5 w-5 -ml-2"></div>
                      <div className="w-1 h-full bg-white shadow-md inset-0 mt-2"></div>
                    </div>
                    <div className="p-3 md:p-6 -mt-8">
                      <h2 className="text-xl md:text-3xl text-primary font-bold text-purple  pt-1">
                        07. Future
                      </h2>
                     
                      <ul className="text-white p-5 leading-6 space-y-12 list-disc">
                        <li>
                          <span className="text-lg font-semibold">
                            Quest Marketplace
                            <br />
                          </span>
                         
                        </li>
                        <li>
                          <span className="text-lg font-semibold">
                            Quest Dashboard <br />
                          </span>
                         
                        </li>
                        <li>
                          <span className="text-lg font-semibold">
                            Mod Profile
                            <br />
                          </span>
                         
                        </li>
                        <li>
                          <span className="text-lg font-semibold">
                            Mod School
                            <br />
                          </span>
                         
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>
            </TabPanel>
          </Box>
        </div>
      </div>
    </section>
  );
}

export default Test;
