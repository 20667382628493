import banner from "../img/banner.png";
import OneImageSlider from "../components/OneImageSlider";

function Banner() {
  return (
    <section className="px-8 md:max-w-6xl py-14 mx-auto w-full">
      <div className="flex items-center space-x-4">
        <figure className="hidden md:inline-flex">
          <img src={banner} alt="" />
        </figure>

        <div>
          <div className="space-y-5 text-2xl md:text-5xl">
            <h2>Perks of Owning a </h2>
            <h2>Mod HippoGryph</h2>
          </div>
          <ul className="list-decimal text-sm md:text-lg space-y-4 p-6">
            <li> Access to MoonShot Funding</li>
            <li>Access to MoonShot Investing</li>
            <li>Access to our MoonShot Marketplace</li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Banner;
