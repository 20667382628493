import React, { Component } from "react";
import Slider from "react-slick";
import s1 from "../img/s1.png";
import s2 from "../img/s2.png";
import s3 from "../img/s3.png";
import s4 from "../img/s4.png";
import s5 from "../img/s5.png";
import s6 from "../img/s6.png";
import s7 from "../img/s7.png";
import s8 from "../img/s8.png";

export default class AutoPlayMethods extends Component {
  constructor(props) {
    super(props);
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
  }
  play() {
    this.slider.slickPlay();
  }
  pause() {
    this.slider.slickPause();
  }
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
    };
    return (
      <div className="">
        {/* <h2>Auto Play & Pause with buttons</h2> */}
        <Slider ref={(slider) => (this.slider = slider)} {...settings}>
          <div>
            <figure>
              <img className="custom" src={s1} alt="" />
            </figure>
          </div>
          <div>
            <figure>
              <img className="custom" src={s2} alt="" />
            </figure>
          </div>
          <div>
            <figure>
              <img className="custom" src={s3} alt="" />
            </figure>
          </div>
          <div>
            <figure>
              <img className="custom" src={s4} alt="" />
            </figure>
          </div>
          <div>
            <figure>
              <img className="custom" src={s5} alt="" />
            </figure>
          </div>
          <div>
            <figure>
              <img className="custom" src={s6} alt="" />
            </figure>
          </div>
          <div>
            <figure>
              <img className="custom" src={s7} alt="" />
            </figure>
          </div>
          <div>
            <figure>
              <img className="custom" src={s8} alt="" />
            </figure>
          </div>
        </Slider>
        {/* <div style={{ textAlign: "center" }}>
          <button className="button" onClick={this.play}>
            Play
          </button>
          <button className="button" onClick={this.pause}>
            Pause
          </button>
        </div> */}
      </div>
    );
  }
}
