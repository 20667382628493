import React, { useState } from "react";
import mask from "../img/mask.png";
import twitter from "../img/twitter.svg";
import instagram from "../img/instagram.svg";

import team1 from "../img/team-1.gif";
import team2 from "../img/team2.png";
import team3 from "../img/team3.png";
import team4 from "../img/team4.png";
import team5 from "../img/team1.png";

import ng from "../img/ng.png";
import bg from "../img/bg.png";
import og from "../img/og.png";
import yg from "../img/yg.png";

// Team Data

import teamData from "../handlers/teamData";

function Team() {
  const [teams, setTeams] = useState(teamData);

  return (
    <section className="px-8 md:max-w-6xl py-12 mx-auto w-full" id="team">
      <div className="flex flex-col items-center">
        <img src={mask} alt="" />
        <h2 className="text-3xl md:text-5xl -mt-28">HippoGryph Team</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-5 md:gap-10 py-14">
        <div className="flex flex-col items-center space-y-2 bg-gray p-5">
          <img src={og} alt="" className="object-contain" />
          <h3 className="text-base md:text-xl text-center">ogMod</h3>
          <p className="text-sm text-center">Founder</p>
          <div className="flex items-center space-x-2">
            <a href="#">
              <img src={twitter} alt="" />
            </a>
            <a href="#">
              <img src={instagram} alt="" />
            </a>
          </div>
        </div>
        <div className="flex flex-col items-center space-y-2 bg-gray p-5">
          <img src={ng} alt="" className="object-contain" />
          <h3 className="text-base md:text-xl text-center">ngModess</h3>
          <p className="text-sm text-center">Partner</p>
          <div className="flex items-center space-x-2">
            <a href="#">
              <img src={twitter} alt="" />
            </a>
            <a href="#">
              <img src={instagram} alt="" />
            </a>
          </div>
        </div>
        <div className="flex flex-col items-center space-y-2 bg-gray p-5">
          <img src={yg} alt="" className="object-contain" />
          <h3 className="text-base md:text-xl text-center">ygMod </h3>
          <p className="text-sm text-center">Developer </p>
          <div className="flex items-center space-x-2">
            <a href="#">
              <img src={twitter} alt="" />
            </a>
            <a href="">
              <img src={instagram} alt="" />
            </a>
          </div>
        </div>
        <div className="flex flex-col  items-center space-y-2 bg-gray p-5 ">
          <img src={bg} alt="" className="object-contain" />
          <h3 className="text-base md:text-xl text-center">bgMod</h3>
          <p className="text-sm text-center">Shaman </p>
          <div className="flex items-center space-x-2">
            <a href="#">
              <img src={twitter} alt="" />
            </a>
            <a href="#">
              <img src={instagram} alt="" />
            </a>
          </div>
        </div>

        {/* <div className="flex flex-col items-center space-y-2 bg-gray p-5 ">
          <img src={team1} alt="" className="object-contain" />
          <h3 className="text-base md:text-xl text-center">Name-X</h3>
          <p className="text-sm text-center">Role-X</p>
          <div className="flex items-center space-x-2">
            <a href="#">
              <img src={twitter} alt="" />
            </a>
            <a href="#">
              <img src={instagram} alt="" />
            </a>
          </div>
        </div> */}
        {/* <div className="flex flex-col items-center space-y-2 bg-gray p-5">
          <img src={team1} alt="" className="object-contain" />
          <h3 className="text-base md:text-xl text-center">Name-Y</h3>
          <p className="text-sm text-center">Role-Y</p>
          <div className="flex items-center space-x-2">
            <a href="#">
              <img src={twitter} alt="" />
            </a>
            <a href="#">
              <img src={instagram} alt="" />
            </a>
          </div>
        </div> */}
      </div>
    </section>
  );
}

export default Team;
