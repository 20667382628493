import React from "react";
import { Link } from "react-router-dom";

function Rise() {
  return (
    <section className="px-8 md:max-w-6xl pt-32 py-20 mx-auto w-full" id="rise">
      <div className="space-y-10 flex flex-col items-center">
        <h2 className="text-center text-3xl md:text-5xl">
          About the VentureVerse™ and the Mod HippoGryph
        </h2>
        <div className="space-y-3">
          <p className="text-justify md:text-center text-sm md:text-lg">
            In a land far away, the sorcerer/magician Atlantes has created a
            magic castle on top of Mt. Carena where illusions keep knights and
            other magicians from rescuing the ogKnight Ruggierio who was
            destined to complete an impossible mission to save the world.
          </p>
          <p className="text-justify md:text-center text-sm md:text-lg">
            It was only with the help of a HippoGryph, half mare/half griffin,
            that the brave and beautiful lady knight Bradamante was able to
            rescue Ruggierio, her love. They fly to the Moon and back and then
            to the magic island of Alcina where Ruggiero embarks on his heroic
            mission and sires a line of heroes to free the world.
          </p>
          <p className="text-justify md:text-center text-sm md:text-lg">
            Yes, the HippoGryph has entered the NFT Metaverse under the name Mod
            HippoGryph. The Mod HippoGryph has returned to free the knights of
            this world, creators, mavericks, pioneers and adventurers, from the
            illusions of the magic castle built on top of Mt. Palo Alto and take
            them on an adventure to the Moon and back to the magical island of
            Sancti.
          </p>
          <p className="text-justify md:text-center text-sm md:text-lg">
            The Mod HippoGryph is ridden by the ogMod, a direct descendent of
            Sir Ruggiero and pioneer of the VentureVerse™ and the ngModess, a
            direct descendant of Lady Bradamante. Your ticket to the Moon and
            back and entrance into the magical island of Sancti is the Mod
            HippoGryph and commitment to the life of the Mod, a life of
            creating, funding and building MoonShots in the Metaverse.
          </p>
          <p className="text-justify md:text-center text-sm md:text-lg">
            The initial Mod HippoGryph collection will consists of 10,000
            tickets to the VentureVerse™. These tickets are your perpetual
            membership to a realm dedicated to the creation of a new world built
            by the public and for the public good.
          </p>
        </div>
        {/* <a
          href="/manifesto"
          className="uppercase bg-blue px-10 py-4 rounded-md inline-block font-semibold border border-white"
        >
          Our Manifesto
        </a> */}
      </div>
    </section>
  );
}

export default Rise;
